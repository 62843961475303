<template>
    <transition
    enter-active-class="duration-300"
    enter-from-class="translate-y-2 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="duration-300 ease-out delay-0"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="translate-y-2 opacity-0">
        <slot />
    </transition>
</template>